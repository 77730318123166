
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import ConstructionStatusService from "@/services/construction-status/construction-status.service";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "ConstructionStatusView",

  data: () => ({
    request: {
      statusId: null as number | null,
    },
    status: null as ConstructionStatusModel | null,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
  }),
  created() {
    const params = this.$route.params as any;
    this.request.statusId = params.id;
  },
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    this.$nextTick(async () => {
      await this.getStatus();
    });
  },
  activated() {
    //console.log("activated");
    const event = this.getPageUpdateEvent();
    //console.log("event : ", event);
    if (event != null) {
      this.getStatus();
      this.updatePreVmEvent(event.result, event.item);
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "download") {
        this.downloadImage();
      } else if (menu.id === "edit") {
        this.showEditPage();
      } else if (menu.id === "delete") {
        this.deleteItem();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    async downloadImage() {
      if (this.status) {
        try {
          const status = this.status as ConstructionStatusModel;
          //console.log("statusId : ", status.id);
          const response = (await ConstructionStatusService.getImageFileList(status.id)) as any;
          //console.log("response : ", response);
          core.loader.show("다운로드중...");
          await core.http.downloadFile(response.downloadUri + "?v=" + new Date().getTime());
        } catch (e) {
          console.log(e);
        } finally {
          core.loader.hide();
        }
      }
    },
    imageHeight(item) {
      let height = 0;
      if (item.imageWidth && item.imageHeight) {
        const size = this.app.size;
        const cal = (size.width - 10) / item.imageWidth;
        height = item.imageHeight * cal;
      }
      return height;
    },
    onView(detail) {
      const imageUri = detail.imageUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
      // window.open(detail.imageUri, "_blank");
    },
    async deleteItem() {
      // console.log("삭제처리");
      try {
        const content =
          this.status?.type === "F"
            ? "하자현황을 삭제하시겠습니까?"
            : "시공현황을 삭제하시겠습니까?";
        const confirmResult = await core.alert.show({
          title: "확인",
          body: content,
          showCancelButton: true,
        });
        if (confirmResult === "confirm") {
          const result = await ConstructionStatusService.delete(this.status?.id as number);
          //console.log("result : ", result);
          this.goBack(UpdateEventType.RELOAD);
        }
      } catch (e) {
        console.log(e);
      }
    },
    showEditPage() {
      this.$router.push({
        path: "/construction-status/edit",
        query: {
          statusId: String(this.request.statusId),
        },
      });
    },
    async getStatus() {
      try {
        core.loader.show();
        this.status = (await ConstructionStatusService.get(
          this.request.statusId as number
        )) as ConstructionStatusModel;
        // console.log("status : ", this.status);
        if (this.status.type === "F") {
          await this.$store.dispatch("topToolbar/changeTitle", {
            title: "하자현황",
            ignoreCheck: true,
          });
        } else {
          await this.$store.dispatch("topToolbar/changeTitle", {
            title: "시공현황",
            ignoreCheck: true,
          });
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      core.loader.hide();
    },
  },
});
